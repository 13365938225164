<template>
  <div>
    <b-dropdown
      dropdown
      :text="time ? time.display : 'Select Time'"
      class="vite-timepicker w-100"
    >
      <b-dropdown-item
        @click="$emit('changed', time)"
        v-for="time in timeList"
        :key="time.display"
      >
        {{ time.display }}
      </b-dropdown-item>
    </b-dropdown>
    <div class="text-danger" style="font-size: 12px" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import { startOfDay, endOfDay, addMinutes, format } from "date-fns";
export default {
  props: {
    time: {},
    required: {
      type: Boolean,
    },
    submitted: {
      type: Boolean,
    },
  },
  model: {
    prop: "time",
    event: "changed",
  },
  watch: {
    submitted: {
      handler(value) {
        if (value && this.required) {
          if (!this.time) {
            this.error = "This field is required";
          } else {
            this.error = "";
          }
        }
      },
    },
  },
  data() {
    return {
      timeList: this.getTimeList(),
      error: "",
    };
  },
  methods: {
    getTimeList() {
      const startDate = startOfDay(new Date());
      const endDate = endOfDay(new Date());
      let list = [];
      for (let date = startDate; date < endDate; date = addMinutes(date, 15)) {
        list = [
          ...list,
          {
            value: {
              date: date,
              hh: date.getHours(),
              mm: date.getMinutes(),
            },
            display: format(date, "HH:mm") + ` ${date.getHours() < 12 ? 'AM' : 'PM'}`,
          },
        ];
      }
      return list;
    },
  },
};
</script>

<style>
.vite-timepicker .dropdown-menu {
  height: 192px;
  overflow-y: scroll;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border: none;
  border-radius: 0;
  width: 100%;
}
.vite-timepicker .dropdown-item {
  height: 45px;
  display: flex;
  align-items: center;
}
.vite-timepicker .dropdown-toggle {
  background-color: #f6f6f6;
  border: none;
  min-height: 50px;
  font-size: 16px;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.vite-timepicker.show .dropdown-toggle,
.vite-timepicker .dropdown-toggle:focus,
.vite-timepicker .dropdown-toggle:active {
  color: #495057 !important;
  background-color: #f6f6f6 !important;
  border-color: #050505 !important;
  border-width: 2px;
  border-style: solid;
}

/* width */
.vite-timepicker ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.vite-timepicker ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.vite-timepicker ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

/* Handle on hover */
.vite-timepicker ::-webkit-scrollbar-thumb:hover {
  background: #666;
}
</style>